<!--
 * @Date: 2021-03-21 19:22:47
 * @LastEditors: frank
 * @LastEditTime: 2021-05-14 08:56:34
 * @FilePath: /shop_frontend/src/views/memberCard/activateCard.vue
 * @Description: Description
-->
<template>
  <section class="page activateCard">
    <div class="formBox">
      <a-form :form="form">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-item label="会员名称">
              <a-input
                placeholder="会员信息"
                style="width: 220px"
                v-model="form.name"
                allowClear
              />
            </a-form-item>
          </a-col>
          <!-- <a-col :span="8">
            <a-form-item label="手机号码">
              <a-input
                placeholder="搜索手机号"
                style="width: 220px"
                v-model="form.phone"
                allowClear
              />
            </a-form-item>
          </a-col> -->

          <!-- <a-col :span="12">
            <a-form-item label="申请时间">
              <a-range-picker allowClear valueFormat="YYYY-MM-DD">
              </a-range-picker>
            </a-form-item>
          </a-col> -->
          <!-- <a-col :span="12">
            <a-form-item label="审核时间">
              <a-range-picker allowClear valueFormat="YYYY-MM-DD">
              </a-range-picker>
            </a-form-item>
          </a-col> -->

          <a-col :span="6">
            <a-form-item>
              <a-button
                type="primary"
                @click="handleSearch"
                style="margin-right: 10px"
              >
                筛选
              </a-button>
              <!-- <a-button type=""> 会员导出 </a-button> -->
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="page__table">
      <a-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="pagination"
        bordered
        @change="changeTable"
      >
        <div slot="audit" slot-scope="text, record">
          <p>审核状态：{{ record.status | showStatus }}</p>
          <p v-if="record.audit_username">
            审核人：{{ record.audit_username || "" }}
          </p>
          <p v-if="record.refusal_reason">
              拒绝原因：{{record.refusal_reason || ''}}
          </p>
        </div>

        <span slot="action" slot-scope="text, record">
          <div class="action_custom" v-if="record.status == 0">
            <a-button type="primary" @click="accept(record)">通过</a-button>
            <a-button @click="openRefuse(record)" style="margin-left: 10px"
              >不通过</a-button
            >
          </div>
        </span>
      </a-table>
    </div>
    <a-modal
      title="审核不通过原因"
      :visible="visible"
      @ok="handleOk"
      @cancel="visible = false"
    >
      <div>
        <a-alert message="原因提交后不可修改，请谨慎提交" banner />
        <div style="margin-top: 10px;">
          <a-textarea placeholder="审核不通过原因" :rows="4" v-model="refusal_reason" />
        </div>
      </div>
    </a-modal>
  </section>
</template>

<script>
const columns = [
  {
    title: "用户ID",
    dataIndex: "id",
    align: 'center'
  },
  {
    title: "昵称",
    dataIndex: "member_name",
    align: 'center'
  },
  {
    title: "手机号码",
    dataIndex: "member_phone",
    align: 'center'
  },
  {
    title: "申请时间",
    dataIndex: "create_time",
    align: 'center'
  },
  {
    title: "审核时间",
    dataIndex: "audit_time",
    align: 'center'
  },
  {
    title: "审核状态",
    dataIndex: "audit",
    scopedSlots: { customRender: "audit" },
    align: 'center'
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: 300,
    align: 'center'
  },
];
export default {
  name: "activateCard",
  data() {
    return {
        refusal_reason: '',
      visible: false,
      form: {
          name: ''
      },
      columns,
      currentObj: {},
      pagination: {
        total: 0,
        showTotal: (total, range) => {
          return `共 ${total} 条`;
        },
        showQuickJumper: true,
        defaultPageSize: 15,
      },
      dataSource: [],
      page: 1,
    };
  },
  components: {},
  computed: {
    info() {
      return JSON.parse(this.$route.query.info);
    },
    params() {
      return {
        page: this.page,
        ...this.form,
        search: this.form.name
        // start_date: this.searchDate[0] || "",
        // end_date: this.searchDate[1] || "",
      };
    },
  },
  filters: {
    showStatus(status) {
      return { 0: "待审核", 1: "审核通过", 2: "审核不通过" }[status];
    },
  },
  watch: {},
  created() {
    this.initTable();
  },
  mounted() {},
  methods: {
      handleSearch () {
          this.initTable();
      },
    handleOk() {
        if (!this.refusal_reason) {
            return 
        }
        this.$axios
        .post(`/card_applications/${this.currentObj.id}/refuse/`, {
            refusal_reason: this.refusal_reason
        })
        .then((res) => {
          this.$message.success("操作成功");
          this.visible = false
          this.initTable();
        });
    },
    openRefuse(obj) {
      this.currentObj = obj;
      this.visible = true;
      this.refusal_reason = ''
    },
    accept(obj) {
      this.$axios
        .post(`/card_applications/${obj.id}/accept/`, {})
        .then((res) => {
          this.$message.success("操作成功");
          this.initTable();
        });
    },
    changeTable(pagination) {
      console.log(pagination);
      this.page = pagination.current;
      this.initTable();
    },
    initTable() {
      this.$axios
        .get("/card_applications/", { params: this.params })
        .then((res) => {
          this.dataSource = res.results || [];
          this.pagination.total = res.count || 0;
        });
    },
  },
};
</script>

<style scoped lang="less">
</style>
